import { getSlug } from '../../../lib/web/meta';

// This function generates a BreadcrumbList schema for SEO purposes
export default function BreadcrumbList({
    disablePrimary = false,
    disableSecondary = false,
    forcePrimary = '',
    primarySlug = '',
    primaryName = '',
    secondarySlug = '',
    secondaryName = '',
    type = ''
}: {
    disablePrimary?: boolean;
    disableSecondary?: boolean;
    forcePrimary?: string;
    type?: string;
    primarySlug?: string;
    primaryName?: string;
    secondarySlug?: string;
    secondaryName?: string;
}) {
    let index = 1;
    // The base items for the BreadcrumbList schema
    const baseItems = [
        {
            '@type': 'ListItem',
            position: index,
            name: 'Home',
            item: 'https://www.rabbitsreviews.com/'
        }
    ];
    // If the type is 'reviews', add a 'Categories' item to the base items
    if (type === 'reviews/') {
        baseItems.push({
            '@type': 'ListItem',
            position: ++index,
            name: primaryName,
            item: `https://www.rabbitsreviews.com/porn/categories`
        });
    }
    // If there is a primary category and it's not disabled, add it to the base items
    if (primarySlug && !disablePrimary) {
        baseItems.push({
            '@type': 'ListItem',
            position: ++index,
            name: primaryName,
            item: `https://www.rabbitsreviews.com/porn/${primarySlug}`
        });
    }
    // If there is a forced primary category, add it to the base items
    if (forcePrimary) {
        baseItems.push({
            '@type': 'ListItem',
            position: ++index,
            name: secondaryName,
            item: `https://www.rabbitsreviews.com/porn/categories/${getSlug(forcePrimary)}`
        });
    }
    // If there is a secondary category and it's not disabled, add it to the base items
    if (secondarySlug && !disableSecondary) {
        baseItems.push({
            '@type': 'ListItem',
            position: ++index,
            name: secondaryName,
            item: `https://www.rabbitsreviews.com/porn/${type}${getSlug(secondarySlug)}`
        });
    }
    // Return the BreadcrumbList schema
    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: baseItems
    };
}
