export default function CIDSchema(siteData) {
    //The schema shows the first 10 sites of the given category. If there's less than 10 sites, then only show the amount of sites.
    const numberLimit = siteData.results.length > 10 ? 10 : siteData.results.length;
    const siteList = [];
    //Create an array of sites to be put into the schema with the limit we established above
    siteData.results.slice(0, numberLimit).map((item) => {
        siteList.push({
            '@type': 'itempage',
            name: `${item.siteItemProps.name}`,
            url: `https://www.rabbitsreviews.com/${item.siteItemProps.id}/${item.siteItemProps.slug}`
        });
    });
    return {
        '@context': 'https://schema.org/',
        '@type': 'collectionpage',
        about: siteData.categoryInfo.slug ? siteData.categoryInfo.slug.split('-').join(' ') : '',
        mainentity: {
            '@type': 'itemlist',
            itemlistelement: siteList
        }
    };
}
